import React from 'react'
import sliderImg from './img/slider-1.webp'
import sliderImg2 from './img/slider-2.webp'
import sliderImg3 from './img/slider-3.webp'
export default function SliderSec() {
  return (
    <div>

        <div id="demo" className="carousel slide" data-bs-ride="carousel">

        
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
            </div>

        
            <div className="carousel-inner">
                <div className="carousel-item active">                
                    <img src={sliderImg} alt="slider"  className='w-100 img-fluid' />  
                </div>
                <div className="carousel-item">
                    <img src={sliderImg2} alt="slider" className='w-100 img-fluid' />
                </div>
                <div className="carousel-item">
                    <img src={sliderImg3} alt="slider" className='w-100 img-fluid' />
                </div>
                <div className='SliderText'>
                    <h1>Your Daily Dose of News and Entertainment</h1>
                    <p className='Mnone'>
                    In today’s modern technological age, the role of media is undeniably pivotal. Media serves as a potent instrument for delivering content to the masses, making it a powerful channel for information dissemination.
                    
                    Audiences exhibit a keen interest in a spectrum of programs, ranging from entertainment to daily news and feature stories.
                    </p>
                </div>
            </div>
            <p className='DesNone'>
                In today’s modern technological age, the role of media is undeniably pivotal. Media serves as a potent instrument for delivering content to the masses, making it a powerful channel for information dissemination.
            
                Audiences exhibit a keen interest in a spectrum of programs, ranging from entertainment to daily news and feature stories.
            </p>

       
            <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                <span className="carousel-control-prev-icon"></span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                <span className="carousel-control-next-icon"></span>
            </button>
        </div>
      
    </div>
  )
}
