import React from 'react'
import abtImg from './img/bhavysMedia.jpg'
export default function BhavysMedia() {
  return (
    <>
    <section className='bg-white'>
      <div className="container py-5">
        <div className="row align-items-center text-center justify-content-center">            
            <div className="col-md-7">
                <h2>
                    Bhavyas Media:
                </h2>
                <p>
                    Bhavyas Media is a well-known YouTube channel that supports new talents in creative fields. They focus on making interesting short films and web series, providing a space for aspiring artists to showcase their skills.
                </p>
                <h6>For more info</h6>
                <a href='https://www.youtube.com/@bhavyasmedia' target='_blank' rel='noopener'><button className='btn btn-danger'>Visit our channel <i className="bi bi-box-arrow-up-right"></i></button></a>
            </div>
        </div>
      </div>      
    </section>
    <section className=''>
        <div className="container py-5">
            <div className="row align-items-center justify-content-center"> 
                <div className="col-md-4">
                    <img src={abtImg} className='img-fluid' alt='About Bhavys Media' />
                </div>
                <div className="col-md-5">
                    <h2>About</h2>
                    <p>Bhavyas Media is committed to telling quality stories and giving budding talents a chance to express their creativity, bringing new perspectives to entertainment.</p>
                </div>
            </div>
        </div>
    </section>
    </>
   
  )
}
