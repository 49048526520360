import React from 'react'

export default function PrivacyPolicy() {
  return (
    <>
    <section className='bg-white'>
      <div className="container pt-5">
        <div className="row align-items-center text-center justify-content-center">              
            <div className="col-md-7">
                <h2>
                    Privacy Policy
                </h2>
                <p>
                  KBK Broadcasting is committed to safeguarding your privacy. This Privacy Policy outlines our practices concerning the collection, use, and protection of your personal information in connection with our media services, including media partnerships with Kapotham for news and ownership of three YouTube channels: Mr. Hyderbadi, Bhavy's Media, and K News Telugu.
                   
                </p>
                <h6>For more info</h6>
                <button className='btn btn-danger'>Check Below Points <i className="bi bi-box-arrow-down"></i></button>
            </div>
        </div>
      </div>
    </section>
    
    <section className=' bg-white'>
        <div className="container">
            <div className="row align-items-center justify-content-center py-5" >
                <div className="col-md-10 py-5">
                  
                <h4>1. Information Collection:</h4>
                  <p>We collect personal information, such as names, email addresses, and user preferences, to enhance your media experience. We may also gather non-personal information for analytical purposes.</p>

                  <h4>2. Media Partnerships:</h4>
                  <p>Our collaboration with Kapotham for news involves sharing relevant information for content creation and distribution. This data exchange adheres to strict confidentiality and is solely for journalistic purposes.</p>

                  <h4>3. YouTube Channels:</h4>
                  <p>For Mr. Hyderbadi, Bhavy's Media, and K News Telugu, we collect viewer data to understand audience preferences. This information helps improve content quality and tailor offerings to meet viewer expectations.</p>

                  <h4>4. Information Use:</h4>
                  <p>We utilize collected data to provide personalized content recommendations, improve our services, and for analytical purposes. Your information is treated with utmost confidentiality.</p>

                  <h4>5. Data Security:</h4>
                  <p>KBK Broadcasting employs industry-standard measures to secure your data. We regularly update security protocols to protect against unauthorized access, disclosure, alteration, or destruction of personal information.</p>

                  <h4>6. Third-Party Links:</h4>
                  <p>Our media services may include links to third-party websites. Please be aware that we are not responsible for their privacy practices. We encourage you to review the privacy policies of these third-party sites.</p>

                  <h4>7. Consent and Changes:</h4>
                  <p>By using KBK Broadcasting's services, you consent to the terms outlined in this Privacy Policy. We reserve the right to modify this policy at any time. Changes will be communicated through our platforms.</p>

                  <h4>8. Contact Us:</h4>
                  <p>For inquiries or concerns regarding your privacy, please contact us at [info@kbkbroadcasting.in]. We are committed to addressing your questions promptly.</p>

                  <p>Thank you for entrusting KBK Broadcasting with your privacy. We are dedicated to providing a secure and enjoyable media experience.</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
