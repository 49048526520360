import './App.css';
import{BrowserRouter, Routes, Route} from 'react-router-dom';
import About from './components/About';
import Navbar from './components/Navbar';
import SliderSec from './components/SliderSec';
import Feature from './components/Feature';
import BrandPartner from './components/BrandPartner';
import CreatingBetter from './components/CreatingBetter';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import KNewsTelugu from './components/KNewsTelugu';
import KapothamTeluguNews from './components/KapothamTeluguNews';
import MrHyderabadi from './components/MrHyderabadi';
import BhavysMedia from './components/BhavysMedia';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Contact from './components/Contact';
import ContactTest from './components/ContactTest';






function App() {
  return (
      
      <BrowserRouter>     
        <Navbar/>
        <div className='mb-100'></div>
        <Routes>
          <Route exact path='/' element={
            <>
              <section className='container'>            
                <SliderSec/>                    
              </section>
              
              <section id='brandParnner'>
                <BrandPartner></BrandPartner>
              </section>
              <Feature></Feature>
              <CreatingBetter></CreatingBetter>
              <ContactForm></ContactForm>
            </>
          } />
          <Route path='about' element={
            <>
              <section className=''>
                <About/>
                <section className='Aboutpage' id='brandParnner'>
                  <BrandPartner></BrandPartner>
                </section>
              </section>
            </>
          } />

          <Route path='k-news-telugu' element={
            <>
              <section className='bg-about'>
                <KNewsTelugu/>
              </section>
            </>
          } /> 

          <Route path='kapotham-telugu-news' element={
            <>
              <section className='bg-about'>
                <KapothamTeluguNews/>
              </section>
            </>
          } /> 

          <Route path='mr-hyderabadi' element={
            <>
              <section className='bg-about'>
                <MrHyderabadi/>
              </section>
            </>
          } /> 

          <Route path='bhavyas-media' element={
            <>
              <section className='bg-about'>
                <BhavysMedia/>
              </section>
            </>
          } />   

          <Route path='privacy-policy' element={
            <>
              <section className='bg-about'>
                <PrivacyPolicy/>
              </section>
            </>
          } />

          <Route path='terms-of-use' element={
            <>
              <section className='bg-about'>
                <Terms/>
              </section>
            </>
          } /> 

          <Route path='contact' element={
            <>
              <section className='bg-about'>
                <Contact/>
              </section>
              <div className='bg-light py-5'>
                <ContactForm/>
              </div>
            </>
          } />   

          <Route path='contact-test' element={
            <>
              <section className='bg-about'>
                <Contact/>
              </section>
              <div className='bg-light py-5'>
                <ContactTest/>
              </div>
            </>
          } />     
          
          
          
        </Routes>
        
           
        <Footer></Footer> 
          
       </BrowserRouter> 
  );
}

export default App;
