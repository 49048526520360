import React from 'react'
import abtImg from './img/aboutImg.jpg';
export default function KNewsTelugu() {
  return (
    <>
      <section className='bg-white'>
        <div className="container py-5">
          <div className="row align-items-center text-center justify-content-center">              
              <div className="col-md-7">
                  <h2>
                      K News Telugu
                  </h2>
                  <p>
                      This influential medium effectively highlights the needs of the public to the government. There exists a perpetual requirement for media that fearlessly conveys the truth without prejudice, bias, or alignment with any political party, upholding social values irrespective of caste, religion, race, or regional distinctions.
                      
                  </p>
                  <h6>For more info</h6>
                  <a href='https://www.youtube.com/@KNewsTelugu1' target='_blank' rel='noopener'><button className='btn btn-danger'>Channel Tour <i className="bi bi-box-arrow-up-right"></i></button></a>
              </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5">
          <div className="row align-items-center justify-content-center">
              <div className="col-md-4">
                  <img src={abtImg} className='img-fluid' alt='About K-News' />
              </div>
              <div className="col-md-5">
                  <h2>
                      About
                  </h2>
                  <p>
                      
                      In the contemporary era dominated by opinion journalism, K News Telugu is committed to unbiased reporting, distinct from mere news journalism.
                      <br/><br/>
                      The channel takes pioneering steps to communicate government intentions to the public and present public opinions to governments without bias. We seek your blessings and wholehearted encouragement as we embark on this journey, aiming for success at every step. The Reporting Team at K News Telugu urges you to subscribe for the latest updates.
                  </p>
                  
              </div>
          </div>
        </div>
      </section>
    </>
  )
}
