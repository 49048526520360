import React from 'react'

export default function CreatingBetter() {
  return (
    <>
        <section className=''>
            <div className="container py-5 cratebet">
                <div className="row align-items-center">
                    <div className="col-md-7">

                    </div>
                    <div className="col-md-5">
                        <h2 className='text-white'>Creating Better Experiences - From </h2>
                        <h2 className='text-danger'>End to End.</h2>
                        <p className='text-white'>
                            KBK Broadcasting helps Hyderabad's leading media and entertainment companies localize, transform, manage, and deliver quality entertainment in any format, to any screen around the world.
                        </p>
                        <p className='text-white'>
                            Our customer-first, solution-based approach means we scale with your workflows while providing the hands-on service and innovative solutions it takes to meet demand.
                        </p>
                        <button className="btn btn-alert text-danger border" >Viewers demand the best…so should you.</button>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
