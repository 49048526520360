import React from 'react'

export default function About() {
  return (
    <div>
      <div className="container py-5">
        <div className="row align-items-center text-center justify-content-center">
            
            <div className="col-md-7">
                <h2 className='py-3 h1'>
                    About
                </h2>
                <h5>Your Daily Dose of News and Entertainment</h5>
                <p>                    
                    In today's modern technological age, the role of media is undeniably pivotal. Media serves as a potent instrument for delivering content to the masses, making it a powerful channel for information dissemination.
                    <br/><br/>
                    Audiences exhibit a keen interest in a spectrum of programs, ranging from entertainment to daily news and feature stories.
                    
                </p>
                <h6>For More info</h6>
                <button className="btn btn-danger">Visit Our Channels & Portals <i className="bi bi-arrow-right-square"></i></button>
            </div>
            
        </div>
      </div>
    </div>
  )
}
