import React from 'react'
import abtImg from './img/mr-hydera.jpg'
export default function MrHyderabadi() {
  return (
    <>
    
    <section className='bg-white'>
      <div className="container py-5">
        <div className="row align-items-center text-center justify-content-center">            
            <div className="col-md-7">
                <h2>
                    Mr. Hyderabadi
                </h2>
                <p>Mr. Hyderabadi is well-known YouTube channels that support new talents in creative fields. They focus on making interesting short films and web series, providing a space for aspiring artists to showcase their skills.</p>
                <a href='https://www.youtube.com/@mr.hyderabadi4718' target='_blank' rel='noopener'><button className='btn btn-danger'>Channel Tour <i className="bi bi-box-arrow-up-right"></i></button></a>
            </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center">
            <div className="col-md-5">
                <img src={abtImg} className='img-fluid' alt='Mr. Hyderabadi' />
            </div>
            <div className="col-md-7">
                <h2>
                    About
                </h2>
                <p>Mr. Hyderabadi is committed to telling quality stories and giving budding talents a chance to express their creativity, bringing new perspectives to entertainment.
                </p>
            </div>
        </div>
      </div>
    </section>
    </>
  )
}
