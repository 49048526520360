import React from 'react'
import abtImg from './img/kapotham-news.jpg'
export default function KapothamTeluguNews() {
  return (
    <>
    <section className='bg-white'>
      <div className="container py-5">
        <div className="row align-items-center text-center justify-content-center">
            
            <div className="col-md-7">
                <h2>
                    Kapotham News Telugu
                </h2>
                <p>
                  In today's technologically advanced era, where the world functions as a global village, individuals seek daily global updates. While current technology facilitates this, historically, pigeons served as messengers.
                </p>
                <h6>For more info</h6>
                <a href='https://kapotham.com/' target='_blank' rel='noopener'><button className='btn btn-danger px-3'>Visit Site <i className="bi bi-box-arrow-up-right"></i></button></a>
            </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center">
            <div className="col-md-4">
                <img src={abtImg} className='img-fluid' alt='About Kapotham' />
            </div>
            <div className="col-md-5">
                <h2>
                    Kapotham News Telugu
                </h2>
                <p>
                  News media inspired the concept of ‘kapotam’—an unbiased story symbolizing purity, peace, and the commencement of a truce. This ‘kapotam’ represents impartiality and honesty, maintaining its white hue amid changing political landscapes. Whether in movies, sports, or various fields, our ‘kapotam’ strives to entertain with informative content.

                  <br/><br/>Across politics, cinema, sports, education, medicine, and lifestyle, our goal is to provide unbiased knowledge efficiently. ‘Kapotam’ serves as our endeavor to present news in its purest form at www.kapotham.com. Access news with a single click. Thank you!
                </p>
                
            </div>
        </div>
      </div>
    </section>
    </>
  )
}
