import React from 'react'
import logo from './img/logo.png'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    <footer className='bg-light border-top border-bottom'>
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-4">
              <a href='/'>
                <img src={logo} alt="logoImage" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-3 mt-3">
              <h6>Quick Links</h6>
              <ul className='text-dec-none ps-0' id='QuickLinks'>
                <li><Link to="about"><i className="bi bi-link-45deg"></i>&nbsp;About</Link></li>
                <li><Link to="contact"><i className="bi bi-link-45deg"></i>&nbsp;Contact</Link></li>
                <li><Link to="privacy-policy"><i className="bi bi-link-45deg"></i>&nbsp;Privacy Policy</Link></li>
                <li><Link to="terms-of-use"><i className="bi bi-link-45deg"></i>&nbsp;Terms of Use</Link></li>
              </ul>
            </div>
            <div className="col-md-5 footer-right">
              
              <div className="row">
                <div className="col-md-12">
                  <h6 className='float-end'>Stay Connected</h6>
                </div>
                <div className="col-md-12">                  
                  <ul className='list-style-none float-end'>
                    <li className='px-2'><a href='https://www.facebook.com/profile.php?id=61553101192343' ><span className="bi bi-facebook h4"> </span></a></li>
                    <li className='px-2'><a href='https://twitter.com/KNewsTelugu1' ><span className="bi bi-twitter h4"> </span></a></li>
                    <li className='px-2'><a href='https://www.youtube.com/channel/UCdUYbD8nBJvaVW2XSw3gY8A' ><span className="bi bi-youtube h4"> </span></a></li>
                    <li className='px-2'><a href='https://www.instagram.com/knewstelugu.info/' ><span className="bi bi-instagram h4"> </span></a></li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <h6 className='float-end'><a className='text-decoration-none primary-text' href='tel: +91 90104 58007'><i className="bi bi-telephone-forward"></i>&nbsp;&nbsp; +91 90104 58007 </a></h6>
                </div>
                <div className="col-md-12">
                  <h6 className='float-end'><a className='text-decoration-none primary-text' href='mailto: info@kbkbroadcasting.in'><i className="bi bi-envelope"></i>&nbsp;&nbsp;info@kbkbroadcasting.in</a></h6>
                </div>
              </div>                
              
            </div>
          </div>
          
        </div>
    </footer>
    <section className='bg-dark'>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className='mt-3 text-light'>Copyright @ 2023 KBK Broadcasting Services.</p>
          </div>
          <div className="col-md-6">
            <a href='https://kbkbusinesssolutions.com'><p className='text-light mt-3 float-end'>Designed and Developed by <span className='text-danger'>KBK Business Solutions</span></p></a>
          </div>
          
        </div>
      </div>
    </section>
    </>
  )
}
