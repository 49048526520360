import React from 'react'

import TopContent from './img/top-content.webp';
import TopShoot from './img/expert-kbkbroadcasting-shooting-team.webp';
import media_service from './img/media-service.webp';
import videoEditing from './img/video-editor.webp';
export default function Feature() {
  return (
    <>
    
    <section>
        <div className="container">
            <div className="row align-items-center justify-content-center mob-cr">
                <div className="col-md-6">
                    <img src={TopContent} alt="Editor/Writer" className='img-fluid' />
                </div>
                <div className="col-md-6">
                    <small className='fw-bold text-danger'>Top</small>
                    <h2>Content / Writer / Editor</h2>
                    <p>Proficient in crafting compelling news articles and scripts that captivate audiences. Experienced in research, interviewing, and adapting to various tones and styles. Committed to delivering engaging and accurate content that resonates with readers and viewers alike.</p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row align-items-center justify-content-center position-relative">
                
                <div className="col-md-6">
                    
                    <small className='fw-bold text-danger'>Expert</small>
                    <h2>Shooting Team</h2>
                    <p>KBK Broadcasting's Shooting Team excels in capturing riveting visuals and compelling footage. Our skilled professionals blend creativity and technical expertise to produce high-quality content. From live events to scripted productions, we bring stories to life with precision and innovation. Join us for a visual journey that exceeds expectations.</p>
                </div>

                <div className="col-md-6">
                    
                    <img src={TopShoot} alt="Shooting Team" className='img-fluid' />
                </div>

            </div>
        </div>

        <div className="container videoEditing">
            <div className="row align-items-center justify-content-center position-relative mob-cr">
                <div className="col-md-6">                    
                    <img src={videoEditing} alt="Camera Person" className='img-fluid rounded-circle' />
                </div>
                
                <div className="col-md-6">
                    <small className='fw-bold text-danger'>Top</small>
                    <h2>Editing / Localization</h2>
                    <p>KBK Broadcasting's Editing and Localization team refines content with precision. From seamless edits to cultural adaptation, we ensure your message resonates globally. Our experts combine technical prowess with creative flair, enhancing the impact of your media. Trust us for meticulous editing and strategic localization that transcends boundaries.</p>
                </div>

                

            </div>
        </div>

        <div className="container">
            <div className="row align-items-center justify-content-center position-relative">
                
                <div className="col-md-6">
                    <small className='fw-bold text-danger'>Digital</small>
                    <h2>Media Service</h2>
                    <p>KBK Broadcasting offers unparalleled Media Services, tailored for your needs. From strategic planning to seamless execution, we elevate your content across platforms. Our comprehensive solutions encompass production, distribution, and analytics, ensuring your message reaches the right audience effectively. Trust us for a transformative media experience.</p>
                </div>

                <div className="col-md-6">
                    
                    <img src={media_service} alt="Media Service"  className='img-fluid' />
                </div>

            </div>
        </div>

    </section>

    </>
  )
}
