import React from 'react'

export default function Terms() {
  return (
    <>

  <section className='bg-white'>
      <div className="container pt-5">
        <div className="row align-items-center text-center justify-content-center">              
            <div className="col-md-7">
                <h2>
                    Terms of Use
                </h2>
                <p>
                Welcome to KBK Broadcasting! These Terms of Use outline the rules and regulations for using our media services, including media partnerships with Kapotham for news and ownership of three YouTube channels: Mr. Hyderbadi, Bhavy's Media, and K News Telugu.
                   
                </p>
                <h6>For more info</h6>
                <button className='btn btn-danger'>Check Below Points <i className="bi bi-box-arrow-down"></i></button>
            </div>
        </div>
      </div>
    </section>
    
    <section className=' bg-white'>
        <div className="container">
            <div className="row align-items-center justify-content-center py-5" >
                <div className="col-md-10 py-5">

                <h4>1. Acceptance of Terms:</h4>
                  <p>By accessing or using KBK Broadcasting's services, you agree to abide by these Terms of Use. If you do not agree with any part of these terms, please refrain from using our services.</p>

                  <h4>2. Content Ownership:</h4>
                  <p>All content, including but not limited to videos, articles, and graphics, on our platforms is the intellectual property of KBK Broadcasting. Unauthorized use or reproduction is strictly prohibited.</p>

                  <h4>3. Media Partnerships:</h4>
                  <p>Our media partnership with Kapotham for news involves sharing information for journalistic purposes. Any use of this shared information must comply with ethical standards and copyright laws.</p>

                  <h4>4. User Conduct:</h4>
                  <p>While using our services, users must adhere to ethical and respectful conduct. Any behavior that violates the rights of others, including harassment or infringement, will not be tolerated.</p>

                  <h4>5. YouTube Channels:</h4>
                  <p>For Mr. Hyderbadi, Bhavy's Media, and K News Telugu, viewers are encouraged to engage responsibly. Any form of inappropriate comments, spam, or abusive behavior will result in the removal of such content and potential banning of the user.</p>

                  <h4>6. Privacy:</h4>
                  <p>Your privacy is crucial to us. Refer to our Privacy Policy for details on how we collect, use, and protect your personal information.</p>

                  <h4>7. Changes to Terms:</h4>
                  <p>KBK Broadcasting reserves the right to modify these Terms of Use at any time. Users will be notified of changes through our platforms. Continued use of our services after modifications constitutes acceptance of the updated terms.</p>

                  <h4>8. Termination of Services:</h4>
                  <p>KBK Broadcasting may terminate or suspend services at its discretion without notice. Users in violation of these terms may be barred from accessing our platforms.</p>
                  
                  <h4>9. Contact Us:</h4>
                  <p>For questions or concerns regarding these Terms of Use, please contact us at [info@kbkbroadcasting.in].</p>


                  <p>Thank you for choosing KBK Broadcasting. We strive to provide an enjoyable and responsible media experience for all our users.</p>
                  
                  
                </div>
            </div>
        </div>
    </section>

    </>
  )
}
