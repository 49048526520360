import React from 'react'
import Kapotham from './img/kapotham.webp'
import KNews from './img/k-news.webp'
import MrHyde from './img/MR.Hyderabadi.webp'
import BhavyasMedia from './img/bhavyas-Media.webp'
export default function BrandPartner() {
  return (
    <>
        <div className='container py-5'>
            <div className="row align-items-center justify-content-center">
                <div className="col-md-4 float-end">
                    <h2 className='float-start'>Distribution Solutions </h2>
                    <p className='float-start'>We have different types of broadcasting channels like blogging, news-channel and youtube channels.</p>
                </div>
                <div className="col-md-6">
                    <div className="row  justify-content-center text-center">
                        <div className="col-md-6 col-6 border-end border-bottom border-2">
                            <a href='https://kapotham.com/' ><img src={Kapotham} className='img-fluid' alt='Kapotham' /></a>
                        </div>
                        <div className="col-md-6 col-6 border-bottom border-2">
                            <a href='https://www.youtube.com/@bhavyasmedia' ><img src={BhavyasMedia} className='img-fluid' alt='Bhavys media' /></a>
                        </div>                        
                        
                        <div className="col-md-6 col-6 border-end border-2">
                            <a href='https://www.youtube.com/@KNewsTelugu1' ><img src={KNews} className='img-fluid' alt='K News Telugu' /></a> 
                        </div>
                        <div className="col-md-6 col-6">
                            <a href='https://www.youtube.com/@mr.hyderabadi4718' ><img src={MrHyde} className='img-fluid' alt='Mr Hyderabadinpm' /></a>   
                        </div>
                    </div>
                </div>              

            </div>
        </div>
    </>
  )
}
