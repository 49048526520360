import React from 'react'

export default function Contact() {
  return (
    
    <section className='bg-white py-5'>
        <div className="container">
            <div className="row align-items-center justify-content-center text-center">
                <div className="col-md-7">
                    <h1>Contact</h1>                
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                    <h6>For more info</h6>
                    <button className='btn btn-danger'>Contact Us! <i className='bi bi-arrow-right-square'></i></button>
                </div>
            </div>
        </div>
    </section>
    
  )
}
