import {React, useState} from 'react';
import{Link} from 'react-router-dom';
import logo from '../logo.png';
export default function Navbar() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNavLinkClick = () => {
        setIsDropdownOpen(false);
    };
  return (
   <>
   
   
   <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top mb-70">
        <div className="container">
            <div className='row'>
                <div className='col-md-3'>
                    <a className="navbar-brand" href="/">
                        <img src={logo} className='img-fluid' alt="logo" />
                    </a>
                </div>
                <div className='col-md-9'>
                <button className="navbar-toggler" type="button"  data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded={isDropdownOpen ? 'true' : 'false'} aria-label="Toggle navigation" onClick={handleDropdownClick}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                    <div className={`collapse navbar-collapse ${isDropdownOpen ? 'show' : ''}`} id="navbarScroll">
                        <ul className="navbar-nav ms-auto py-3 my-lg-0 me-4 navbar-nav-scroll">
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="/">Home</Link>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className='nav-link' onClick={handleNavLinkClick}>
                                <Link to="/about">About</Link>
                                </span>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/"  id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded={isDropdownOpen ? 'true' : 'false'}>
                                    Channel Partner
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown" onClick={handleDropdownClick}>
                                    <li><span className="dropdown-item" onClick={handleNavLinkClick}><Link to="k-news-telugu">K-News Telugu</Link></span></li>
                                    <li><span className="dropdown-item" onClick={handleNavLinkClick}><Link to="kapotham-telugu-news">Kapotham Telugu News</Link></span></li>
                                    <li><span className="dropdown-item" onClick={handleNavLinkClick}><Link to="mr-hyderabadi">Mr. Hyderbadi</Link></span></li>
                                    <li><span className="dropdown-item" onClick={handleNavLinkClick}><Link to="bhavyas-media">Bhavy's Media</Link></span></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <span className='nav-link'>
                                <a className="nav-link-external" href="https://api.whatsapp.com/send?phone=919010458007&text=Hi%20I%20have%20query%20about%20%20" target="_blank" rel="noopener noreferrer" onClick={handleNavLinkClick}>
                                    <i className="bi bi-whatsapp"></i> Chat
                                </a>
                                </span>
                            </li>
                        </ul>                        
                    </div>
                </div>
            </div>
        </div>
      </nav>
      
    
  </>
  )
}



// Navbar.defaultProps={
//     title: 'Logo/Brand',
//     activePageName:'Active Page Name'
// }